import React from 'react';
import { CreateWithPermissions } from '@semapps/auth-provider';
import CompetenceForm from './CompetenceForm';
import { LinearProgress, useGetIdentity, useGetList } from 'react-admin';

const CompetenceCreate = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { data: listOfCompetence, isLoading: competenceLoading} = useGetList('Competence');
  
  if (identityLoading || competenceLoading) return <LinearProgress />;

  let idUser = ""
  let idComp = 0

  idUser = identity.id.substring(identity.id.lastIndexOf('/') + 1)
  const filteredlistOfCompetence = listOfCompetence.filter(item => item["dc:creator"] && item["dc:creator"].includes(identity.id))
  idComp = filteredlistOfCompetence.length+1;

  const transform = (dataTransform) => {
    return ({
    ...dataTransform,
    'pair:label': idUser+"_comp_"+idComp
  })}

return (
<CreateWithPermissions transform={transform}>
  <CompetenceForm />
</CreateWithPermissions>
)}

export default CompetenceCreate;
