import React from 'react';
import CommitmentForm from './CommitmentForm';
import { EditWithPermissions } from '@semapps/auth-provider';

const CommitmentEdit = props => (
  <EditWithPermissions>
    <CommitmentForm />
  </EditWithPermissions>
);

export default CommitmentEdit;
