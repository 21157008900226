import React from 'react';
import { TabbedForm, TextInput, ImageField } from 'react-admin';
import { LocationInput, 
  ThemesInput, 
  JobsInput, 
  TrainingsInput, 
  CommitmentsInput, 
  CompetencesInput
} from '../../../common/input';
import { ImageInput } from '@semapps/input-components';
import { EditWithPermissions } from '@semapps/auth-provider';

export const PersonEdit = props => (
  <EditWithPermissions
    transform={data => ({ ...data, 'pair:label': `${data['foaf:givenName']} ${data['foaf:familyName']?.toUpperCase()}` })}
    {...props}
    redirect="show"
  >
    <TabbedForm>
      <TabbedForm.Tab label="app.tab_data">
        <TextInput source="foaf:givenName" fullWidth />
        <TextInput source="foaf:familyName" fullWidth />
        <TextInput source="rdfs:comment" fullWidth />
        <LocationInput source="pair:hasLocation" fullWidth />
        <ThemesInput source="heco:hasTopic" fullWidth />
        <ImageInput source="image" accept="image/*">
          <ImageField source="src" />
        </ImageInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.tab_cv">
        <JobsInput source="heco:hasJob" />
        <CommitmentsInput source="heco:hasCommitment" />
        <TrainingsInput source="heco:hasTraining" />
        <CompetencesInput source="heco:hasCompetence" />
      </TabbedForm.Tab>
    </TabbedForm>
  </EditWithPermissions>
);

export default PersonEdit;
