import React from 'react';
import { LexiconImportForm, fetchESCO } from '@semapps/interop-components';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { CreateWithPermissions } from '@semapps/auth-provider';
import { useTranslate } from 'react-admin';

const OccupationCreate = () => {
  const translate=useTranslate();
  
  return (
  <>
  <CreateWithPermissions>
    <LexiconImportForm
      fetchLexicon={fetchESCO('https://esco.commondata.one', 'occupation')}
      selectData={data => ({
        'pair:label': data.label,
        'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
      })}
      redirect="show"
    />
  </CreateWithPermissions>
  <Alert severity="warning" fullWidth>
  {translate('app.help_esco')}
  </Alert>
  </>
)};

export default OccupationCreate;
