import React from 'react';
import { CreateWithPermissions } from '@semapps/auth-provider';
import TrainingForm from './TrainingForm';

const CompetenceCreate = () => (
  <CreateWithPermissions>
    <TrainingForm />
  </CreateWithPermissions>
)

export default CompetenceCreate;
