import React, { useMemo } from 'react';
import { Layout as RaLayout } from 'react-admin';
import TreeMenu from './TreeMenu/TreeMenu';
import { useCheckAuthenticated } from "@semapps/auth-provider";
import MyAppBar from './MyAppBar';

const Layout = ({ menu, children, labelNbLines, ...otherProps }) => {
  useCheckAuthenticated();
  const LayoutTreeMenu = useMemo(() => props => <TreeMenu {...props} labelNbLines={labelNbLines} />, [labelNbLines]);
  return (
    <RaLayout
      {...otherProps}
      appBar={MyAppBar}
      menu={menu ? menu : LayoutTreeMenu}
    >
      {children}
    </RaLayout>
  );
};

export default Layout;