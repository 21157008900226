import React from 'react';
import { Show } from 'react-admin';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RedirectByType from "../../common/RedirectByType";


const ResourceRedirect = props => (
  <Show {...props}>
    <RedirectByType 
      typesMap={{ 
        Occupation: 'heco:Occupation',
        Job: 'heco:Job',
        Commitment: 'heco:Commitment',
        Training: 'heco:Training',
        Competence: 'heco:Competence'
      }} />
  </Show>
);

export default {
  config: {
    show: ResourceRedirect,
    icon: HowToRegIcon,
    options: {
      label: 'app.menu.cv'
    }
  },
  dataModel: {
    types: ['heco:Occupation', 'heco:Job', 'heco:Commitment', 'heco:Training', 'heco:Competence'],
    list: {
      servers: '@default',
    }
  },
  translations: {
    fr: {
      name: 'Compétence |||| Compétences'
    }
  }
};
