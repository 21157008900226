import React from 'react';
import { AppBar, Link, TitlePortal, LocalesMenuButton } from 'react-admin';
import { Zoom, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UserMenu } from "@semapps/auth-provider";

const useStyles = makeStyles(theme => ({
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '24px'
    }
  },
  spacer: {
    flex: 1
  },
  searchFormContainer: {
    minWidth: 240,
    flex: 2,
    margin: '0 5%',
    [theme.breakpoints.up('md')]: {
      minWidth: 360
    }
  },
  searchFormWrapper: {
    maxWidth: 880,
    margin: 'auto'
  },
  presContainer: {
    flex: 1,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      flex: 'unset',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  },
  titleContainer: {
    flex: 1,
    padding: "10px",
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      flex: 'unset',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  },
  logoContainer: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      height: 48,
      marginLeft: '0.2em',
      marginRight: '0.2em',
      display: 'block'
    }
  },
  logo: {
    height: '100%'
  },
  title: {
    display: 'block',
    color: theme.palette.primary.contrastText
  },
  userMenu: {
    float: 'right',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& button': {
      padding: '6px 12px'
    }
  },
}));

const MyAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar
      color="primary"
      userMenu={<UserMenu />}
      {...props}
      toolbar={
        <LocalesMenuButton />
      }
    >
      <Link to="/">
        <div className={classes.presContainer}>
          <div className={classes.logoContainer}>
            <Zoom in={true} timeout={2000}>
              <img className={classes.logo} src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" />
            </Zoom>
          </div>
          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant="h6" noWrap>
              {props.title} -
            </Typography>
          </div>
        </div>
      </Link>
      <TitlePortal />
    </AppBar>
  );
};

export default MyAppBar;