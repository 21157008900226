import React from 'react';
import { Avatar } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { SimpleList } from 'react-admin';
import { ListWithPermissions } from '@semapps/auth-provider';
import { useMediaQuery } from '@mui/material';
import OrganizationFilterSidebar from './OrganizationFilterSidebar';

const OrganizationList = props => {
  const xs = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <ListWithPermissions perPage={25} aside={<OrganizationFilterSidebar />}>
      {xs ? (
        <SimpleList primaryText="%{pair:label}" />
    ) : (
        <SimpleList
          primaryText={record => record['pair:label']}
          secondaryText={record => record['rdfs:comment']}
          leftAvatar={record => (
            <Avatar src={record['image']} width="100%">
              <HomeIcon />
            </Avatar>
          )}
          linkType="show"
        />
)}
    </ListWithPermissions>
  )
};

export default OrganizationList;
