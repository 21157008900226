import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { CreateWithPermissions } from '@semapps/auth-provider';

const PageCreate = () => (
  <CreateWithPermissions>
    <SimpleForm>
      <TextInput source="semapps:title" fullWidth />
    </SimpleForm>
  </CreateWithPermissions>
);

export default PageCreate;
