import React from 'react';
import TrainingForm from './TrainingForm';
import { EditWithPermissions } from '@semapps/auth-provider';

const TrainingEdit = props => (
  <EditWithPermissions>
    <TrainingForm />
  </EditWithPermissions>
);

export default TrainingEdit;
