import React from 'react';
import ProjectForm from './ProjectForm';
import { EditWithPermissions } from '@semapps/auth-provider';

const ProjectEdit = props => (
  <EditWithPermissions>
    <ProjectForm />
  </EditWithPermissions>
);

export default ProjectEdit;
