import React from 'react';
import { ChipList } from '@semapps/list-components';
import { QuickAppendReferenceArrayField } from '@semapps/field-components';
import { SimpleList, Datagrid, FunctionField, TextField } from 'react-admin';
import { ListWithPermissions } from '@semapps/auth-provider';
import { useMediaQuery } from '@mui/material';
import ProjectFilterSidebar from './ProjectFilterSidebar';

const ProjectList = props => {
  const xs = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <ListWithPermissions perPage={25} aside={<ProjectFilterSidebar />}>
      {xs ? (
        <SimpleList primaryText="%{pair:label}" />
    ) : (
        <Datagrid rowClick="edit">
          <TextField source="pair:label" />
          <FunctionField label="Description" render={record => {
            if (!record?.["pair:description"]) return 
            return `${record["pair:description"]}`.substring(0, 30) + "..."
          } }/>
          <QuickAppendReferenceArrayField reference="Commitment" source="heco:inverseOfContributedTo">
            <ChipList primaryText="pair:label" linkType="edit" externalLinks />
          </QuickAppendReferenceArrayField>
        </Datagrid>
      )}
    </ListWithPermissions>
  )
};

export default ProjectList;
