import OrganizationCreate from './OrganizationCreate';
import OrganizationShow from './OrganizationShow';
import OrganizationEdit from './OrganizationEdit';
import OrganizationList from './OrganizationList';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export default {
  config: {
    list: OrganizationList,
    create: OrganizationCreate,
    edit: OrganizationEdit,
    show: OrganizationShow,
    icon: AccountBalanceIcon,
    options: {
      label: 'app.menu.organizations',
      parent: 'Actor'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Organization'],
    list: {
      servers: '@default',
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Organisation |||| Organisations',
      fields: {
        'pair:label': 'Nom',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'foaf:homepage': 'Site web',
        image: 'Logo',
        'pair:hasType': 'Type',
        'pair:hasLocation': 'A pour localité',
        'pair:hasStatus': 'Statut',
        'heco:hasTopic': 'A pour thème (Config > Concepts > Thèmes)',
        'heco:inverseOfHeldAt': 'Est lié aux poste(s)',
        'heco:inverseOfContributedTo': 'Est lié aux contribution(s)',
        'heco:inverseOfProvidedBy': 'Est lié aux formation(s)',
      }
    },
    en: {
      name: 'Organization |||| Organizations',
      fields: {
        'pair:label': 'Name',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'foaf:homepage': 'Web site',
        image: 'Logo',
        'pair:hasType': 'Type',
        'pair:hasLocation': 'Locality',
        'pair:hasStatus': 'Status',
        'heco:hasTopic': 'Linked to theme (Config > Concepts > Thèmes)',
        'heco:inverseOfHeldAt': 'Linked to post(s)',
        'heco:inverseOfContributedTo': 'Linked to contribution(s)',
        'heco:inverseOfProvidedBy': 'Linked to training(s)',
      }
    }
  }
};
