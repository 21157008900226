import React from 'react';
import { TabbedForm, TextInput, SelectInput, ImageField } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { ThemesInput,
  JobsInput,
  TrainingsInput,
  LocationInput
 } from '../../../common/input';
import { ImageInput} from '@semapps/input-components';
// import { ReferenceInput, ImageInput} from '@semapps/input-components';
import ReferenceInput from '../../../common/input/ReferenceInput';
import { MultiLinesInput } from '@semapps/input-components';

const OrganizationForm = () => {
  return (
    <TabbedForm>
      <TabbedForm.Tab label="app.tab_data">
        <TextInput source="pair:label" fullWidth />
        <TextInput source="rdfs:comment" fullWidth />
        <MarkdownInput multiline source="pair:description" fullWidth />
        <ReferenceInput reference="Status" source="pair:hasStatus" filter={{ a: 'pair:OrganizationStatus' }}>
          <SelectInput optionText="pair:label" />
        </ReferenceInput>
        <ReferenceInput reference="Type" source="pair:hasType" filter={{ a: 'pair:OrganizationType' }}>
          <SelectInput optionText="pair:label" />
        </ReferenceInput>
        <MultiLinesInput source="foaf:homepage" fullWidth />
        <LocationInput source="pair:hasLocation" fullWidth />
        <ImageInput source="image" accept="image/*">
          <ImageField source="src" />
        </ImageInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.tab_relation">
        <JobsInput source="heco:inverseOfHeldAt" />
        <TrainingsInput source="heco:inverseOfProvidedBy" />
        <ThemesInput source="heco:hasTopic" />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default OrganizationForm;
