import React from 'react';
import { FormTab, TextInput, TabbedForm } from 'react-admin';
import { JobsInput, ThemesInput } from '../../../common/input';
import { EditWithPermissions } from '@semapps/auth-provider';

export const OccupationEdit = () => (
  <EditWithPermissions>
    <TabbedForm redirect="show">
      <FormTab label="app.tab_data">
        <TextInput source="pair:label" fullWidth />
      </FormTab>
      <FormTab label="app.tab_relation">
        <JobsInput source="heco:isOccupationOf" />
        <ThemesInput source="heco:hasTopic" />
      </FormTab>
    </TabbedForm>
  </EditWithPermissions>
);

export default OccupationEdit;
