import React from 'react';
import SimpleList from "../../../common/list/SimpleList";
import DisciplineIcon from '@mui/icons-material/Category';
import { ListWithPermissions } from '@semapps/auth-provider';

const DisciplineList = () => (
  <ListWithPermissions>
    <SimpleList primaryText={record => record['pair:label']} leftAvatar={() => <DisciplineIcon />} linkType="show" />
  </ListWithPermissions>
);

export default DisciplineList;
