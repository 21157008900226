import React from 'react';
import { MarkdownField } from '../../../common/field';
import { MainList } from '../../../common/list';
import { Show } from 'react-admin';

const PageShow = (props) => (
  <Show {...props}>
    <MainList>
      <MarkdownField source="semapps:content" addLabel={false} />
    </MainList>
  </Show>
);

export default PageShow;
