import React from 'react';
import { TextInput, useResourceContext } from 'react-admin';
import { CreateOrImportForm } from '@semapps/interop-components';
import { useDataModel } from '@semapps/semantic-data-provider';
import Create from "../layout/create/Create";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const CreateOrImport = props => {
  const resource = useResourceContext({});
  const dataModel = useDataModel(resource);
  return (
    <>
    <Create {...props}>
      <CreateOrImportForm stripProperties={['heco:topicOf']}>
        {dataModel?.fieldsMapping?.title &&
          <TextInput source={dataModel?.fieldsMapping?.title} fullWidth />
        }
      </CreateOrImportForm>
    </Create>
    <Alert severity="info" fullWidth>
      <AlertTitle>La création se fait en deux temps.</AlertTitle>
    1) Création de l'objet via son titre > Enregistrement
    <br/>2) Formulaire pour remplir les champs de l'objet > Enregistrement
    </Alert>
    </>
  );
}

export default CreateOrImport;
