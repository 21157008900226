import React from 'react';
import { TextInput, TabbedForm, SelectInput } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { OrganizationsInput, 
  ThemesInput, 
  DateTimeInput, 
  CompetencesInput, 
  OccupationsInput } from '../../../common/input';
// import { ReferenceInput} from '@semapps/input-components';
import ReferenceInput from '../../../common/input/ReferenceInput';

const JobForm = () => {
  return (
    <TabbedForm>
      <TabbedForm.Tab label="app.tab_data">
        <TextInput source="pair:label" fullWidth />
        <TextInput source="rdfs:comment" fullWidth />
        <ReferenceInput reference="Type" source="pair:hasType" filter={{ a: 'pair:JobType' }}>
          <SelectInput optionText="pair:label" />
        </ReferenceInput>
        <MarkdownInput multiline source="pair:description" fullWidth />
        <DateTimeInput source="heco:startDate" />
        <DateTimeInput source="heco:endDate" />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.tab_relation">
        <OrganizationsInput source="heco:heldAt" />
        <OccupationsInput source="heco:hasOccupation" />
        <CompetencesInput source="heco:acquiredCompetence" />
        <ThemesInput source="heco:hasTopic" />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default JobForm;
