import JobCreate from './JobCreate';
import JobEdit from './JobEdit';
import JobList from './JobList';
import WorkIcon from '@mui/icons-material/Work';

export default {
  config: {
    list: JobList,
    create: JobCreate,
    edit: JobEdit,
    icon: WorkIcon,
    options: {
      label: 'app.menu.jobs',
      parent: 'Resource'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Job'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Poste |||| Postes',
      fields: {
        'pair:label': 'Titre',
        'pair:description': 'Description',
        'rdfs:comment': 'Courte description',
        'heco:startDate': 'Date de début',
        'heco:endDate': 'Date de fin',
        'heco:heldAt': 'Travaille pour',
        'heco:hasOccupation': 'A pour profession(s)',
        'heco:hasTopic': 'A pour thème',
        'pair:hasType': 'A pour type',
        'heco:isJobOf': 'Détenu par',
        'dc:creator': 'Créé par',
        'heco:acquiredCompetence': 'A produit la (les) compétence(s)'
      }
    },
    en: {
      name: 'Job |||| jobs',
      fields: {
        'pair:label': 'Title',
        'pair:description': 'Description',
        'rdfs:comment': 'Short description',
        'heco:startDate': 'Start date',
        'heco:endDate': 'End date',
        'heco:heldAt': 'Held at',
        'heco:hasOccupation': 'Has occupation(s)',
        'heco:hasTopic': 'Has topic(s)',
        'pair:hasType': 'Has type',
        'heco:isJobOf': 'Is job of',
        'dc:creator': 'Creator',
        'heco:acquiredCompetence': 'Acquired competence(s)'
      }
    }
  }
};
