import StatusCreate from './StatusCreate';
import TypeEdit from './StatusEdit';
import StatusList from './StatusList';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default {
  config: {
    list: StatusList,
    create: StatusCreate,
    edit: TypeEdit,
    icon: VisibilityIcon,
    options: {
      label: 'app.menu.status',
      parent: 'Concept'
    }
  },
  dataModel: {
    types: [
      'pair:Status',
      'pair:OrganizationStatus',
      'pair:ProjectStatus',
    ],
    list: {
      servers: '@default'
    },
    fieldsMapping: {
      title: 'pair:label'
    }
  },
  translations: {
    fr: {
      name: 'Statut |||| Statuts',
      fields: {
        '@type': 'Classe',
        'pair:label': 'Nom'
      }
    },
    en: {
      name: 'Status |||| Status',
      fields: {
        '@type': 'Class',
        'pair:label': 'Name'
      }
    }
  }
};
