import PersonEdit from './PersonEdit';
import PersonList from './PersonList';
import PersonShow from './PersonShow';
import PersonIcon from '@mui/icons-material/Person';

export default {
  config: {
    list: PersonList,
    show: PersonShow,
    edit: PersonEdit,
    icon: PersonIcon,
    options: {
      label: 'app.menu.persons',
      parent: 'Actor'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Person'],
    list: {
      servers: '@default',
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Personne |||| Personnes',
      fields: {
        'foaf:givenName': 'Prénom',
        'foaf:familyName': 'Nom de famille',
        'rdfs:comment': 'En deux mots',
        image: 'Photo',
        'heco:hasTopic': 'A pour intérêt',
        'pair:hasLocation': 'Adresse',
        'heco:hasJob': 'A occupé / occupe comme poste',
        'heco:hasTraining': 'A suivi la (ou les) formation(s)',
        'heco:hasCommitment': 'A contribué à',
        'heco:hasCompetence': 'A pour compétence(s)'
      }
    },
    en: {
      name: 'Person |||| Persons',
      fields: {
        'foaf:givenName': 'Given Name',
        'foaf:familyName': 'Family Name',
        'rdfs:comment': 'In two words',
        image: 'Photo',
        'heco:hasTopic': 'Interest',
        'pair:hasLocation': 'Location',
        'heco:hasJob': 'Has jobs',
        'heco:hasTraining': 'Has training',
        'heco:hasCommitment': 'Has commitment',
        'heco:hasCompetence': 'Has competence'
      }
    }
  }
};
