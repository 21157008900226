import React from 'react';
import { Grid } from '@mui/material';
import { MainList, SideList } from '../../../common/list';
import { MarkdownField } from '@semapps/markdown-components';
import { ShowWithPermissions } from '@semapps/auth-provider';
import { QuickAppendReferenceArrayField } from '@semapps/field-components';
import ChipList from '../../../common/list/ChipList';

const ThemeShow = () => {
return (
  <ShowWithPermissions>
    <Grid container spacing={5}>
      <Grid item xs={12} sm={9}>
        <MainList>
          <MarkdownField source="pair:description" />
        </MainList>
      </Grid>
      <Grid item xs={12} sm={3}>
        <SideList>
          <QuickAppendReferenceArrayField reference="Competence" source="heco:topicOf">
            <ChipList primaryText="pair:label" linkType="" />
          </QuickAppendReferenceArrayField>
        </SideList>
      </Grid>
    </Grid>
  </ShowWithPermissions>
)};

export default ThemeShow;
