import React from 'react';
import { CreateWithPermissions } from '@semapps/auth-provider';
import { LexiconImportForm, fetchWikidata } from '@semapps/interop-components';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTranslate } from 'react-admin';

const DisciplineCreate = () => {
  const translate=useTranslate();
  return (
  <>
    <CreateWithPermissions>
      <LexiconImportForm
        fetchLexicon={fetchWikidata()}
        selectData={data => ({
          'pair:label': data.label,
          'rdfs:comment': data.summary,
          'http://www.w3.org/ns/prov#wasDerivedFrom': data.uri,
        })}
        redirect="show"
      />
    </CreateWithPermissions>
    <Alert severity="warning" fullWidth>
    {translate('app.help_wikidata')}
    </Alert>
  </>
)};

export default DisciplineCreate;
