import React from 'react';
import { FormTab, TabbedForm, TextInput } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { ActorsInput } from '../../../common/input';
import { EditWithPermissions } from '@semapps/auth-provider';

export const ThemeEdit = () => (
  <EditWithPermissions>
    <TabbedForm redirect="show">
      <FormTab label="app.tab_data">
        <TextInput source="pair:label" fullWidth />
        <MarkdownInput multiline source="pair:description" fullWidth />
      </FormTab>
      <FormTab label="app.tab_relation">
        <ActorsInput source="heco:topicOf" />
      </FormTab>
    </TabbedForm>
  </EditWithPermissions>
);

export default ThemeEdit;
