import React from 'react';
import { TabbedForm, TextInput } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { ThemesInput,
  ProjectsInput,
  DateTimeInput, 
  CompetencesInput } from '../../../common/input';

const CommitmentForm = () => {
  return (
    <TabbedForm redirect="edit">
      <TabbedForm.Tab label="app.tab_data">
        <TextInput source="pair:label" fullWidth />
        <TextInput source="rdfs:comment" fullWidth />
        <MarkdownInput multiline source="pair:description" fullWidth />
        <DateTimeInput source="heco:startDate" />
        <DateTimeInput source="heco:endDate" />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.tab_relation">
        <ProjectsInput source="heco:contributedTo" />
        <CompetencesInput source="heco:acquiredCompetence" />
        <ThemesInput source="heco:hasTopic" />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default CommitmentForm;
