import React from 'react';
import { TabbedForm, FormTab } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { 
  DisciplinesInput, 
  AreasInput, 
  StudiedObjectsInput, 
  ToolsInput, 
  JobsInput, 
  CommitmentsInput, 
  TrainingsInput
} from '../../../common/input';
import { Grid } from '@mui/material';

const ComptetenceForm = () => {
  return (
    <TabbedForm>
      <FormTab label="app.tab_data">
        <Grid container spacing={5} fullWidth>
          <Grid item xs={4}>
            <MarkdownInput multiline source="pair:description"/>
          </Grid>
          <Grid item xs={8}>
            <DisciplinesInput source="heco:hasDiscipline" fullWidth/>
            <AreasInput source="heco:hasArea" />
            <StudiedObjectsInput source="heco:hasStudiedObject" />
            <ToolsInput source="heco:hasTool" />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="app.tab_relation">
        <JobsInput source="heco:isAcquiredByJob" />
        <CommitmentsInput source="heco:isAcquiredByCommitment" />
        <TrainingsInput source="heco:isAcquiredByTraining" />
      </FormTab>
    </TabbedForm>
  );
};

export default ComptetenceForm;
