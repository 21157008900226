import React from 'react';
import { CreateWithPermissions } from '@semapps/auth-provider';
import ProjectForm from './ProjectForm';

const ProjectCreate = () => (
  <CreateWithPermissions>
    <ProjectForm />
  </CreateWithPermissions>
)

export default ProjectCreate;
