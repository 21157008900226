import React from 'react';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import { ShowWithPermissions } from '@semapps/auth-provider';
import RedirectByType from "../../common/RedirectByType";

const ActorRedirect = () => (
  <ShowWithPermissions>
    <RedirectByType typesMap={
      { Person: 'heco:Person', 
      Organization: 'heco:Organization', 
      Project: 'heco:Project' 
    }} />
  </ShowWithPermissions>
);

export default {
  config: {
    show: ActorRedirect,
    icon: SupervisedUserCircleRoundedIcon,
    options: {
      label: 'app.menu.actors'
    }
  },
  dataModel: {
    types: ['heco:Organization', 'heco:Person', 'heco:Project'],
    list: {
      servers: '@default',
    }
  },
  translations: {
    fr: {
      name: 'Acteur |||| Acteurs'
    },
    en: {
      name: 'Actor |||| Actors'
    }
  }
};
