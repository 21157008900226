import React from 'react';
import StyleIcon from '@mui/icons-material/Style';
import SimpleList from "../../../common/list/SimpleList";
import { ListWithPermissions } from '@semapps/auth-provider';

const TypeList = () => (
  <ListWithPermissions>
    <SimpleList
      primaryText={record => record['pair:label']}
      secondaryText={record => record.type}
      leftAvatar={() => <StyleIcon />}
    />
  </ListWithPermissions>
);

export default TypeList;
