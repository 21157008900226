import React from 'react';
import { SvgIcon } from '@mui/material';

const RoundIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 26 26" {...props}>
      <path d="M12.6745 0.010498C5.68572 0.010498 0 5.6961 0 12.6849C0 19.6736 5.68572 25.3592 12.6745 25.3592C19.6632 25.3592 25.3488 19.6736 25.3488 12.6849C25.3488 5.6961 19.6632 0.010498 12.6745 0.010498ZM12.6745 21.3758C7.88217 21.3758 3.98332 17.4769 3.98332 12.6847C3.98332 7.89256 7.88217 3.9937 12.6745 3.9937C17.4667 3.9937 21.3655 7.89256 21.3655 12.6847C21.3655 17.4769 17.4667 21.3758 12.6745 21.3758Z" />
      <circle cx="13" cy="13" r="8.5" fill="white" />
    </SvgIcon>
  );
};

export default RoundIcon;
