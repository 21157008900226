import React from 'react';
import { ChipList } from '@semapps/list-components';
import { QuickAppendReferenceArrayField } from '@semapps/field-components';
import { SimpleList, Datagrid, FunctionField, TextField } from 'react-admin';
import { ListWithPermissions } from '@semapps/auth-provider';
import { useMediaQuery } from '@mui/material';
import TrainingFilterSidebar from './TrainingFilterSidebar';

const TrainingList = props => {
  const xs = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <ListWithPermissions perPage={25} aside={<TrainingFilterSidebar />}>
      {xs ? (
        <SimpleList primaryText="%{pair:label}" />
        ) : (
        <Datagrid rowClick="edit">
          <TextField source="pair:label" />
          <FunctionField label="Description" render={record => {
            if (!record?.["pair:description"]) return 
            return `${record["pair:description"]}`.substring(0, 30) + "..."
          } }/>
          <QuickAppendReferenceArrayField reference="Person" source="dc:creator">
            <ChipList primaryText="pair:label" linkType="show" externalLinks />
          </QuickAppendReferenceArrayField>
          <QuickAppendReferenceArrayField reference="Person" source="heco:isTrainingOf">
             <ChipList primaryText="pair:label" linkType="show" externalLinks />
          </QuickAppendReferenceArrayField>
        </Datagrid>
      )}
    </ListWithPermissions>
  )
};

export default TrainingList;
