import React from 'react';
import { CreateWithPermissions } from '@semapps/auth-provider';
import JobForm from './JobForm';

const JobCreate = () => (
  <CreateWithPermissions>
    <JobForm />
  </CreateWithPermissions>
)

export default JobCreate;
