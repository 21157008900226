import React from 'react';
import { SimpleList } from 'react-admin';
import { ListWithPermissions } from '@semapps/auth-provider';
import { useMediaQuery } from '@mui/material';
import PersonFilterSidebar from './PersonFilterSidebar';
import { GridList } from '@semapps/list-components';
import { AvatarWithLabelField } from '@semapps/field-components';

const PersonList = props => {
  const xs = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <ListWithPermissions perPage={25} aside={<PersonFilterSidebar />}>
      {xs ? (
        <SimpleList primaryText="%{pair:label}" />
    ) : (
      <GridList xs={6} sm={2} linkType="show" externalLinks>
        <AvatarWithLabelField label="pair:label" image="image" labelColor='primary' />
      </GridList>
)}
    </ListWithPermissions>
  )
};

export default PersonList;
