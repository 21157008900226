import CommitmentCreate from './CommitmentCreate';
import CommitmentEdit from './CommitmentEdit';
import CommitmentList from './CommitmentList';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default {
  config: {
    list: CommitmentList,
    create: CommitmentCreate,
    edit: CommitmentEdit,
    icon: ThumbUpIcon,
    options: {
      label: 'app.menu.commitments',
      parent: 'Resource'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Commitment'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Contribution |||| Contributions',
      fields: {
        'pair:label': 'Titre',
        'pair:description': 'Description',
        'rdfs:comment': 'Courte description',
        'heco:startDate': 'Date de début',
        'heco:endDate': 'Date de fin',
        'heco:contributedTo': 'A contribué aux projets',
        'heco:hasTopic': 'A pour thème',
        'heco:isCommitmentOf': 'Liée à',
        'dc:creator': 'Créé par',
        'heco:acquiredCompetence': "A produit la (les) compétences"
      }
    },
    en: {
      name: 'Commitment |||| Commitments',
      fields: {
        'pair:label': 'Title',
        'pair:description': 'Description',
        'rdfs:comment': 'Short description',
        'heco:startDate': 'Start date',
        'heco:endDate': 'End date',
        'heco:contributedTo': 'Contributed to (projects)',
        'heco:hasTopic': 'Has topic',
        'heco:isCommitmentOf': 'Is commitment of',
        'dc:creator': 'Creator',
        'heco:acquiredCompetence': "Acquired competence"
      }
    }
  }
};
