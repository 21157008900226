import SettingsIcon from '@mui/icons-material/Settings';

export default {
  config: {
    icon: SettingsIcon,
    options: {
      label: 'app.menu.config'
    }
  },
  dataModel: {
    types: ['heco:Topic'],
    list: {
      servers: '@default',
    }
  },
  translations: {
    fr: {
      name: 'Configuration |||| Configurations'
    },
    en: {
      name: 'Config |||| Configs'
    }
  }
};
