import * as React from 'react';
import { 
  useListContext, 
  Link, 
  useCreatePath, 
  TextField, 
  RecordContextProvider, 
  ReferenceField,
  useResourceContext
} from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import { Icon, Box, Typography, LinearProgress } from '@mui/material';
import RoundIcon from '../../svg/RoundIcon';
import DateToDateField from '../field/DateToDateField';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/timeline.png)`,
    backgroundPosition: '0 10px',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '54px',
    paddingLeft: 50,
    marginTop: 20,
  },
  event: {
    paddingBottom: 30,
    '&:last-of-type': {
      paddingBottom: 5,
    },
  },
  roundIcon: {
    position: 'absolute',
    top: -5,
    left: -35,
  },
  place: {
    "& strong, & span": {
      fontWeight: 600,
      color: theme.palette.primary.main
    }
  }
}));

const TimelineList = () => {
  const classes = useStyles();
  const createPath = useCreatePath();
  const resource = useResourceContext({});
  const { data, isLoading } = useListContext();

  if (isLoading) return <LinearProgress />;
  if (data.length === 0) return <p>No data</p>;

  return (
    <Box className={classes.root}>
      {data.map((record) => {
        return (
          <Box position="relative" className={classes.event}>
            <Icon className={classes.roundIcon} color="primary">
              <RoundIcon />
            </Icon>
            <RecordContextProvider value={record} key={record.id}>
              <p>
                <DateToDateField startDate="heco:startDate" endDate="heco:endDate" variant="subtitle1" color="primary" />
              </p>
              <Link to={createPath( {resource:resource, type:'show', id:record.id} )} onClick={(e) => e.stopPropagation()}>
                <Typography variant="body2" color="primary" paragraph>
                  <strong>{record['pair:label']}</strong>
                </Typography>
              </Link>
              <p>
                <TextField source="rdfs:comment" variant="body2" />
              </p>
              { record['pair:hostedIn'] &&
                <Typography variant="body2" color="primary" paragraph>
                  <ReferenceField record={record} source="pair:hostedIn" reference="Place" linkType="show" className={classes.place}>
                    <strong>Lieu: <TextField source="pair:label" /></strong>
                  </ReferenceField>
                </Typography>
              }
              { ! record['pair:hostedIn'] && record['pair:hasLocation'] &&
                <Typography variant="body2" color="primary" paragraph>
                  <strong>Localisation: {record['pair:hasLocation']['pair:label']}</strong>
                </Typography>
              }
              <p>
                <TextField source="pair:hostedIn.rdfs:comment" variant="body2" />
              </p>
            </RecordContextProvider>
          </Box>
        )
      })}
    </Box>
  );
};

export default TimelineList;
