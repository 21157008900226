import React from 'react';
import ComptetenceForm from './CompetenceForm';
import { EditWithPermissions } from '@semapps/auth-provider';

const ComptetenceEdit = () => {
  return (
    <EditWithPermissions>
      <ComptetenceForm />
    </EditWithPermissions>
  );
};

export default ComptetenceEdit;
