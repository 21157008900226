import React from 'react';
import { Avatar } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import SimpleList from "../../../common/list/SimpleList";
import { ListWithPermissions } from '@semapps/auth-provider';

const PageList = () => (
  <ListWithPermissions>
    <SimpleList
      primaryText={record => record['semapps:title']}
      leftAvatar={() => (
        <Avatar width="100%">
          <DescriptionIcon />
        </Avatar>
      )}
      linkType="show"
    />
  </ListWithPermissions>
);

export default PageList;
