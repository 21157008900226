import React from 'react';
import { TextField } from 'react-admin';
import { Grid, Avatar } from '@mui/material';
import { MapField } from '@semapps/geo-components';
import { ReferenceArrayField, QuickAppendReferenceArrayField, MultiUrlField, SeparatedListField } from '@semapps/field-components';
import { ChipList } from '@semapps/list-components';
import { MarkdownField } from '../../../common/field';
import { Hero, MainList, SideList } from '../../../common/list';
import { ShowWithPermissions } from '@semapps/auth-provider';
import ForumIcon from '@mui/icons-material/Forum';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

const domainMapping = {
  'forums.assemblee-virtuelle.org': {
    label: 'Forum',
    icon: <ForumIcon />,
    color: '#28ccfb',
    contrastText: 'white'
  },
  'peertube.virtual-assembly.org': {
    label: 'Peertube',
    icon: <VideocamOutlinedIcon />,
    color: 'white',
    contrastText: '#f2690d'
  },
  'chat.lescommuns.org': {
    label: 'Chat',
    icon: <Avatar src="/lescommuns.jpg" />,
    color: 'white',
    contrastText: 'black'
  }
}

const OrganizationShow = () => (
  <ShowWithPermissions>
    <Grid container spacing={5}>
      <Grid item xs={12} sm={9}>
        <Hero image="image">
          <TextField source="rdfs:comment" />
          <MultiUrlField source="foaf:homepage" domainMapping={domainMapping} />
          <ReferenceArrayField reference="Status" source="pair:hasStatus">
            <SeparatedListField link={false}>
              <TextField source="pair:label" />
            </SeparatedListField>
          </ReferenceArrayField>
          <ReferenceArrayField reference="Type" source="pair:hasType">
            <SeparatedListField link={false}>
              <TextField source="pair:label" />
            </SeparatedListField>
          </ReferenceArrayField>
        </Hero>
        <MainList>
          <MarkdownField source="pair:description" />
          <MapField
            source="pair:hasLocation"
            address={record => record['pair:hasLocation'] && record['pair:hasLocation']['pair:label']}
            latitude={record => record['pair:hasLocation'] && record['pair:hasLocation']['schema:latitude']}
            longitude={record => record['pair:hasLocation'] && record['pair:hasLocation']['schema:longitude']}
          />
        </MainList>
      </Grid>
      <Grid item xs={12} sm={3}>
        <SideList>
          <QuickAppendReferenceArrayField reference="Job" source="heco:inverseOfHeldAt">
            <ChipList primaryText="pair:label" linkType="edit" externalLinks />
          </QuickAppendReferenceArrayField>
          <QuickAppendReferenceArrayField reference="Commitment" source="heco:inverseOfContributedTo">
            <ChipList primaryText="pair:label" linkType="edit" externalLinks />
          </QuickAppendReferenceArrayField>
          <QuickAppendReferenceArrayField reference="Training" source="heco:inverseOfProvidedBy">
            <ChipList primaryText="pair:label" linkType="edit" externalLinks />
          </QuickAppendReferenceArrayField>
          <QuickAppendReferenceArrayField reference="Theme" source="heco:hasTopic">
            <ChipList primaryText="pair:label" linkType="edit" externalLinks />
          </QuickAppendReferenceArrayField>
        </SideList>
      </Grid>
    </Grid>
  </ShowWithPermissions>
);

export default OrganizationShow;
