import React from 'react';
import { CreateWithPermissions } from '@semapps/auth-provider';
import CommitmentForm from './CommitmentForm';

const CommitmentCreate = () => (
  <CreateWithPermissions>
    <CommitmentForm />
  </CreateWithPermissions>
)

export default CommitmentCreate;
