import CompetenceCreate from './CompetenceCreate';
import CompetenceEdit from './CompetenceEdit';
import CompetenceList from './CompetenceList';
import CompetenceIcon from '@mui/icons-material/Grade';

export default {
  config: {
    list: CompetenceList,
    create: CompetenceCreate,
    edit: CompetenceEdit,
    icon: CompetenceIcon,
    options: {
      label: 'app.menu.competences',
      parent: 'Resource'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Competence'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Competence |||| Competences',
      fields: {
        'pair:label': 'Titre',
        'pair:description': 'Description',
        'heco:hasDiscipline': 'Discipline(s)',
        'heco:hasArea': 'Secteur(s)',
        'heco:hasStudiedObject': "Objet(s) d'étude",
        'heco:hasTool': 'Outil(s)',
        'heco:isCompetenceOf': 'Détenue par',
        'dc:creator': 'Créé par',
        'heco:isAcquiredByJob': 'Acquis à travers le(s) poste(s)',
        'heco:isAcquiredByCommitment': 'Acquis à travers la (les) contribution(s)',
        'heco:isAcquiredByTraining': 'Acquis à travers la (les) formation(s)'
      }
    },
    en: {
      name: 'Competence |||| Competences',
      fields: {
        'pair:label': 'Title',
        'pair:description': 'Description',
        'heco:hasDiscipline': 'Discipline(s)',
        'heco:hasArea': 'Area(s)',
        'heco:hasStudiedObject': "Studied objet(s)",
        'heco:hasTool': 'Tool(s)',
        'heco:isCompetenceOf': 'Is competence of',
        'dc:creator': 'Creator',
        'heco:isAcquiredByJob': 'Is acquired by job(s)',
        'heco:isAcquiredByCommitment': 'Is acquired by commitment(s)',
        'heco:isAcquiredByTraining': 'Is acquired by training(s)'
      }
    }
  }
};
