import { dataProvider as semanticDataProvider } from '@semapps/semantic-data-provider';
import ontologies from './ontologies.json';
import dataServers from './dataServers';
import * as resources from '../resources';

const dataProvider = semanticDataProvider({
  dataServers,
  resources: Object.fromEntries(Object.entries(resources).map(([k, v]) => [k, v.dataModel])),
  ontologies,
  jsonContext: [
    "https://www.w3.org/ns/activitystreams",
    process.env.REACT_APP_MIDDLEWARE_URL + '.well-known/context.jsonld'
    ]
});

export default dataProvider;
