import OccupationCreate from './OccupationCreate';
import OccupationEdit from './OccupationEdit';
import OccupationList from './OccupationList';
import OccupationShow from './OccupationShow';
import BusinessIcon from '@mui/icons-material/Business';

export default {
  config: {
    list: OccupationList,
    create: OccupationCreate,
    edit: OccupationEdit,
    show: OccupationShow,
    icon: BusinessIcon,
    options: {
      label: 'app.menu.occupations',
      parent: 'Quality'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Occupation'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Profession |||| Professions',
      fields: {
        'pair:label': 'Libellé de la profession',
        'pair:description': 'Description',
        'heco:isOccupationOf': 'Lié aux poste(s)',
        'heco:hasTopic': 'A pour thème(s)',
        'prov:wasDerivedFrom': 'Provient de...'
      }
    },
    en: {
      name: 'Occupation |||| Occupations',
      fields: {
        'pair:label': 'Title',
        'pair:description': 'Description',
        'heco:isOccupationOf': 'Is occupation of (poste(s))',
        'heco:hasTopic': 'Has topic(s)',
        'prov:wasDerivedFrom': 'Was derived From...'
      }
    }
  }
};
