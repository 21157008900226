import React from 'react';
import { TextInput, TabbedForm } from 'react-admin';
import { MarkdownInput } from '@semapps/markdown-components';
import { OrganizationsInput, ThemesInput, DateTimeInput, CompetencesInput } from '../../../common/input';

const TrainingForm = () => {
  return (
    <TabbedForm>
      <TabbedForm.Tab label="app.tab_data">
        <TextInput source="pair:label" fullWidth />
        <TextInput source="rdfs:comment" fullWidth />
        <MarkdownInput multiline source="pair:description" fullWidth />
        <DateTimeInput source="heco:startDate" />
        <DateTimeInput source="heco:endDate" />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="app.tab_relation">
        <OrganizationsInput source="heco:providedBy" />
        <CompetencesInput source="heco:acquiredCompetence" />
        <ThemesInput source="heco:hasTopic" />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export default TrainingForm;
