import ProjectCreate from './ProjectCreate';
import ProjectEdit from './ProjectEdit';
import ProjectList from './ProjectList';
import ProjectShow from './ProjectShow';
import ProjectIcon from '@mui/icons-material/GpsFixed';

export default {
  config: {
    list: ProjectList,
    show: ProjectShow,
    create: ProjectCreate,
    edit: ProjectEdit,
    icon: ProjectIcon,
    options: {
      label: 'app.menu.projects',
      parent: 'Actor'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Project'],
    list: {
      servers: '@default',
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Projet |||| Projets',
      fields: {
        'pair:label': 'Nom',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'foaf:homepage': 'Site web',
        image: 'Logo',
        'pair:hasType': 'Type',
        'pair:hasStatus': 'Statut',
        'heco:hasTopic': 'A pour thème (Concepts > Thèmes)',
        'heco:inverseOfContributedTo': 'Est lié aux contribution(s)'
      }
    },
    en: {
      name: 'Project |||| Projects',
      fields: {
        'pair:label': 'Name',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'foaf:homepage': 'Web Site',
        image: 'Logo',
        'pair:hasType': 'Hase type',
        'pair:hasStatus': 'Has status',
        'heco:hasTopic': 'Has thème(s) (Concepts > Thèmes)',
        'heco:inverseOfContributedTo': 'Linked to contribution(s)'
      }
    }
  }
};
