import React from 'react';
import JobForm from './JobForm';
import { EditWithPermissions } from '@semapps/auth-provider';

const JobEdit = props => (
  <EditWithPermissions>
    <JobForm />
  </EditWithPermissions>
);

export default JobEdit;
