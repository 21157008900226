import ToolCreate from './ToolCreate';
import ToolEdit from './ToolEdit';
import ToolList from './ToolList';
import ToolShow from './ToolShow';
import ToolIcon from '@mui/icons-material/Category';

export default {
  config: {
    list: ToolList,
    show: ToolShow,
    create: ToolCreate,
    edit: ToolEdit,
    icon: ToolIcon,
    options: {
      label: 'app.menu.tools',
      parent: 'Quality'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Tool'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Outil |||| Outils',
      fields: {
        'pair:label': 'Titre',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'heco:isToolOf': "Est l'outil de",
        'prov:wasDerivedFrom': 'Provient de...'
      }
    },
    en: {
      name: 'Tool |||| Tools',
      fields: {
        'pair:label': 'Title',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'heco:isToolOf': "Is tool of",
        'prov:wasDerivedFrom': 'Was derived From...'
      }
    }
  }
};
