import * as React from 'react';
import { 
  useListContext, 
  Link, 
  TextField, 
  RecordContextProvider, 
  useCreatePath, 
  useResourceContext 
} from 'react-admin';
import { Box, Typography, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/timeline.png)`,
    backgroundPosition: '0 10px',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '54px',
    paddingLeft: 50,
    marginTop: 20,
  },
  event: {
    paddingBottom: 30,
    '&:last-of-type': {
      paddingBottom: 5,
    },
  },
  roundIcon: {
    position: 'absolute',
    top: -5,
    left: -35,
  },
  place: {
    "& strong, & span": {
      fontWeight: 600,
      color: theme.palette.primary.main
    }
  }
}));

const CompetenceQualityList = () => {
  const classes = useStyles();
  const { data, isLoading } = useListContext();
  const createPath = useCreatePath();
  const resource = useResourceContext({});

  if (isLoading) return <LinearProgress />;
  if (data.length === 0) return <p>No data</p>;

  return (
    <Box className={classes.root}>
      {data.map((record) => {
        return (
          <Box position="relative" className={classes.event}>
            <RecordContextProvider value={record} key={record.id}>
              <Link to={createPath( {resource:resource, type:'show', id:record.id} )} onClick={(e) => e.stopPropagation()}>
                <Typography variant="body2" color="primary" paragraph>
                  <strong>{record['pair:label']}</strong>
                </Typography>
              </Link>
              <p>
                <TextField source="rdfs:comment" variant="body2" />
              </p>
            </RecordContextProvider>
          </Box>
        )
      })}
    </Box>
  );
};

export default CompetenceQualityList;
