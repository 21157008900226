import StudiedObjectCreate from './StudiedObjectCreate';
import StudiedObjectEdit from './StudiedObjectEdit';
import StudiedObjectList from './StudiedObjectList';
import StudiedObjectShow from './StudiedObjectShow';
import StudiedObjectIcon from '@mui/icons-material/Category';

export default {
  config: {
    list: StudiedObjectList,
    show: StudiedObjectShow,
    create: StudiedObjectCreate,
    edit: StudiedObjectEdit,
    icon: StudiedObjectIcon,
    options: {
      label: "app.menu.studiedobjects",
      parent: 'Quality'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:StudiedObject'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: "Objet d'étude |||| Objets d'étude",
      fields: {
        'pair:label': 'Titre',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'heco:isStudiedObjectOf': "Est l'objet d'étude de",
        'prov:wasDerivedFrom': 'Provient de...'
      }
    },
    en: {
      name: "Studied object |||| Studied objects",
      fields: {
        'pair:label': 'Title',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'heco:isStudiedObjectOf': "Is studied object of",
        'prov:wasDerivedFrom': 'Was derived From...'
      }
    }
  }
};
