import React from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SimpleList from "../../../common/list/SimpleList";
import { ListWithPermissions } from '@semapps/auth-provider';

const ThemeList = () => (
  <ListWithPermissions>
    <SimpleList primaryText={record => record['pair:label']} leftAvatar={() => <LocalOfferIcon />} linkType="show" />
  </ListWithPermissions>
);

export default ThemeList;
