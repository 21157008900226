import DisciplineCreate from './DisciplineCreate';
import DisciplineEdit from './DisciplineEdit';
import DisciplineList from './DisciplineList';
import DisciplineShow from './DisciplineShow';
import DisciplineIcon from '@mui/icons-material/Category';

export default {
  config: {
    list: DisciplineList,
    show: DisciplineShow,
    create: DisciplineCreate,
    edit: DisciplineEdit,
    icon: DisciplineIcon,
    options: {
      label: 'app.menu.disciplines',
      parent: 'Quality'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Discipline'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Discipline |||| Disciplines',
      fields: {
        'pair:label': 'Titre',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'heco:isDisciplineOf': 'Est la discipline de',
        'prov:wasDerivedFrom': 'Provient de...'
      }
    },
    en: {
      name: 'Discipline |||| Disciplines',
      fields: {
        'pair:label': 'Title',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'heco:isDisciplineOf': 'Is discipline of',
        'prov:wasDerivedFrom': 'Was derived From...'
      }
    }
  }
};
