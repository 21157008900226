import React from 'react';
// import { SsoLoginPage } from '@semapps/auth-provider';
import SsoLoginPage from './SsoLoginPage';
import { Avatar, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(theme => ({
  root: {
    padding : 10,
    margin : 10,
  },
}));

const LoginPage = (props) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  return <SsoLoginPage 
    text='app.login_page'
    buttons={[
    <Button startIcon={<Avatar src="/lescommuns.png" />}>{translate("app.login_button_1")}</Button>
  ]} {...props} 
  />
};

export default LoginPage;
