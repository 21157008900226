import React from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';

const ResourceMenuLink = ({ resource, onClick, open }) => {
  const translate = useTranslate();
  return (
  <MenuItemLink
    to={`/${resource.name}`}
    primaryText={(resource.options && translate(resource.options.label)) || resource.name}
    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
    onClick={onClick}
    sidebarIsOpen={open}
  />
)};

export default ResourceMenuLink;
