import React from 'react';
import { TextField } from 'react-admin';
import { Grid, Avatar } from '@mui/material';
import { MainList, SideList } from '../../../common/list';
import { MarkdownField } from '@semapps/markdown-components';
import { ShowWithPermissions } from '@semapps/auth-provider';
import { MultiUrlField, QuickAppendReferenceArrayField } from '@semapps/field-components';
import ChipList from '../../../common/list/ChipList';

const domainMapping = {
  'www.wikidata.org': {
    label: 'Wikidata',
    icon: <Avatar component="span" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Wikidata-logo.svg/800px-Wikidata-logo.svg.png" />,
    color: 'grey',
    contrastText: 'white'
  }
}

const DisciplineShow = () => {
  return(
  <ShowWithPermissions>
    <Grid container spacing={5}>
      <Grid item xs={12} sm={9}>
        <MainList>
          <TextField source="rdfs:comment" />
          <MultiUrlField source="prov:wasDerivedFrom" domainMapping={domainMapping}/>
          <MarkdownField source="pair:description" />
        </MainList>
      </Grid>
      <Grid item xs={12} sm={3}>
        <SideList>
          <QuickAppendReferenceArrayField reference="Competence" source="heco:isDisciplineOf">
            <ChipList primaryText="pair:label" linkType="edit" externalLinks />
          </QuickAppendReferenceArrayField>
        </SideList>
      </Grid>
    </Grid>
  </ShowWithPermissions>
)};

export default DisciplineShow;
