import React from 'react';
import { TextField } from 'react-admin';
import { Grid } from '@mui/material';
import { QuickAppendReferenceArrayField, ReferenceArrayField } from '@semapps/field-components';
import { MapField } from '@semapps/geo-components';
import { Hero, MainList, SideList } from '../../../common/list';
import { ShowWithPermissions } from '@semapps/auth-provider';
import TimelineList from '../../../common/list/TimelineList';
import CompetenceQualityList from '../../../common/list/CompetenceQualityList';
import { ChipList } from '@semapps/list-components';

const PersonShow = () => (
  <ShowWithPermissions>
    <Grid container spacing={5}>
      <Grid item xs={12} sm={9}>
        <Hero image="image">
          <TextField source="foaf:givenName" />
          <TextField source="foaf:familyName" />
          <TextField source="rdfs:comment" />
          <ReferenceArrayField
            label="app.menu.jobs"
            reference="Job"
            source="heco:hasJob"
            sort={{ field: 'heco:startDate', order: 'ASC' }}
          >
            <TimelineList />
          </ReferenceArrayField>
          <ReferenceArrayField
            label="app.menu.commitments"
            reference="Commitment"
            source="heco:hasCommitment"
            sort={{ field: 'heco:startDate', order: 'ASC' }}
          >
            <TimelineList />
          </ReferenceArrayField>
          <ReferenceArrayField
            label="app.menu.trainings"
            reference="Training"
            source="heco:hasTraining"
            sort={{ field: 'heco:startDate', order: 'ASC' }}
          >
            <TimelineList />
          </ReferenceArrayField>
          <ReferenceArrayField
            label="app.menu.competences"
            reference="Competence"
            source="heco:hasCompetence"
          >
            <CompetenceQualityList/>
          </ReferenceArrayField>
        </Hero>
        <MainList>
          <MapField
            source="pair:hasLocation"
            address={record => record['pair:hasLocation'] && record['pair:hasLocation']['pair:label']}
            latitude={record => record['pair:hasLocation'] && record['pair:hasLocation']['schema:latitude']}
            longitude={record => record['pair:hasLocation'] && record['pair:hasLocation']['schema:longitude']}
          />
        </MainList>
      </Grid>
      <Grid item xs={12} sm={3}>
        <SideList>
          <QuickAppendReferenceArrayField reference="Theme" source="heco:hasTopic">
            <ChipList primaryText="pair:label" linkType="show" externalLinks />
          </QuickAppendReferenceArrayField>
        </SideList>
      </Grid>
    </Grid>
  </ShowWithPermissions>
);

export default PersonShow;
