import AreaCreate from './AreaCreate';
import AreaEdit from './AreaEdit';
import AreaList from './AreaList';
import AreaShow from './AreaShow';
import AreaIcon from '@mui/icons-material/Category';

export default {
  config: {
    list: AreaList,
    show: AreaShow,
    create: AreaCreate,
    edit: AreaEdit,
    icon: AreaIcon,
    options: {
      label: 'app.menu.areas',
      parent: 'Quality'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Area'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Secteur |||| Secteurs',
      fields: {
        'pair:label': 'Titre',
        'rdfs:comment': 'Courte description',
        'pair:description': 'Description',
        'heco:isAreaOf': 'Est le secteur de',
        'prov:wasDerivedFrom': 'Provient de...'
      }
    },
    en: {
      name: 'Area |||| Areas',
      fields: {
        'pair:label': 'Title',
        'rdfs:comment': 'Short description',
        'pair:description': 'Description',
        'heco:isAreaOf': 'is Area Of',
        'prov:wasDerivedFrom': 'Was derived from...'
      }
    }
  }
};
