import TrainingCreate from './TrainingCreate';
import TrainingEdit from './TrainingEdit';
import TrainingList from './TrainingList';
import SchoolIcon from '@mui/icons-material/School';

export default {
  config: {
    list: TrainingList,
    create: TrainingCreate,
    edit: TrainingEdit,
    icon: SchoolIcon,
    options: {
      label: 'app.menu.trainings',
      parent: 'Resource'
    },
    recordRepresentation: 'pair:label'
  },
  dataModel: {
    types: ['heco:Training'],
    list: {
      servers: '@default'
    },
    // fieldsMapping: {
    //   title: 'pair:label'
    // }
  },
  translations: {
    fr: {
      name: 'Formation |||| Formations',
      fields: {
        'pair:label': 'Titre',
        'pair:description': 'Description',
        'rdfs:comment': 'Courte description',
        'heco:startDate': 'Date de début',
        'heco:endDate': 'Date de fin',
        'heco:providedBy': 'Formé par',
        'heco:hasTraining': 'A pour formation',
        'heco:hasTopic': 'A pour thème',
        'pair:hasType': 'A pour type',
        'heco:isTrainingOf': 'Suivie par',
        'dc:creator': 'Créé par',
        'heco:acquiredCompetence': 'A produit la (les) compétence(s)'
      }
    },
    en: {
      name: 'Training |||| Trainings',
      fields: {
        'pair:label': 'Title',
        'pair:description': 'Description',
        'rdfs:comment': 'Short description',
        'heco:startDate': 'Start date',
        'heco:endDate': 'End date',
        'heco:providedBy': 'Provided by',
        'heco:hasTraining': 'Has training',
        'heco:hasTopic': 'Has topic(s)',
        'pair:hasType': 'Has type',
        'heco:isTrainingOf': 'Is training of',
        'dc:creator': 'Creator',
        'heco:acquiredCompetence': 'Acquired competence(s)'
      }
    }
  }
};
