import React from 'react';
import { SimpleForm, TextInput, SelectArrayInput } from 'react-admin';
import { CreateWithPermissions } from '@semapps/auth-provider';

const TypeCreate = () => (
  <CreateWithPermissions>
    <SimpleForm>
      <TextInput source="pair:label" fullWidth />
      <SelectArrayInput
        source="@type"
        choices={[
          { id: 'pair:Type', name: 'Type' },
          { id: 'pair:ProjectType', name: "Type de projet" },
          { id: 'pair:OrganizationType', name: "Type d'organisation" },
          { id: 'pair:JobType', name: 'Type de poste' },
        ]}
      />
    </SimpleForm>
  </CreateWithPermissions>
);

export default TypeCreate;
