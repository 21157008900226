import React from 'react';
import SimpleList from "../../../common/list/SimpleList";
import BusinessIcon from '@mui/icons-material/Business';
import { ListWithPermissions } from '@semapps/auth-provider';
import OccupationFilterSidebar from './OccupationFilterSidebar';

const OccupationList = () => (
  <ListWithPermissions perPage={25} aside={<OccupationFilterSidebar />}>
    <SimpleList primaryText={record => record['pair:label']} leftAvatar={() => <BusinessIcon />} />
  </ListWithPermissions>
);

export default OccupationList;
