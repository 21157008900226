import React from 'react';
import { Show } from 'react-admin';
import CategoryIcon from '@mui/icons-material/Category';
import RedirectByType from "../../common/RedirectByType";


const ResourceRedirect = props => (
  <Show {...props}>
    <RedirectByType 
      typesMap={{ 
        Discipline: 'heco:Discipline',
        Area: 'heco:Area',
        StudiedObject: 'heco:StudiedObject',
        Tool: 'heco:Tool'
      }} />
  </Show>
);

export default {
  config: {
    show: ResourceRedirect,
    icon: CategoryIcon,
    options: {
      label: 'app.menu.referentials'
    }
  },
  dataModel: {
    types: ['heco:Discipline', 'heco:Area', 'heco:StudiedObject', 'heco:Tool'],
    list: {
      servers: '@default',
    }
  },
  translations: {
    fr: {
      name: 'Qualité |||| Qualités'
    }
  }
};
