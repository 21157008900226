import React from 'react';
import OrganizationForm from './OrganizationForm';
import { EditWithPermissions } from '@semapps/auth-provider';

const OrganizationEdit = props => (
  <EditWithPermissions>
    <OrganizationForm />
  </EditWithPermissions>
);

export default OrganizationEdit;
